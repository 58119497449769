














import {
  useFindMasterTaxType,
  useMapMasterTypeToOptionAlt,
} from "@/hooks/master-type";
import { Option } from "@/models/class/option.class";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectTaxCalculation extends Vue {
  @Prop({ required: true, type: [String, Object], default: "" })
  value!: string | LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  excludeNone!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option[] = [];

  loading = false;

  created(): void {
    this.fetchOptions();
  }

  onChange(e: string | LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchOptions(): void {
    this.loading = true;
    useFindMasterTaxType()
      .then(res => {
        const options = useMapMasterTypeToOptionAlt(res);
        let filtered = options;

        if (this.excludeNone) {
          filtered = options.filter(
            e => e.value.toUpperCase() !== TAX_CALCULATION.NONE.toUpperCase()
          );
        }

        this.options = filtered;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
